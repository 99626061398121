// @flow
import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Tooltip,
  Button,
  MenuItem,
  Box
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { useTheme } from '@material-ui/core/styles'
import { VictoryPie } from 'victory'

import { Snackbar, TextField } from 'components'
import { formatAmount } from 'utils'
import { useGet, usePost } from 'hooks'

const REPORT_TYPES = {
  DAILY: 'Diario',
  MONTHY: 'Mensual'
}

function Welcome() {
  const [reportType, setReportType] = useState(REPORT_TYPES.DAILY)
  const { data, loading, error, refetch, setUrl } = useGet(
    'chart/todayPayments'
  )
  const {
    post,
    loading: postLoading,
    error: postError,
    isError: postIsError
  } = usePost()

  const {
    palette: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor }
    }
  } = useTheme()

  const handleOnChangeLocation = (ev: Object) => {
    setReportType(ev.target.value)

    setUrl(
      ev.target.value === REPORT_TYPES.DAILY
        ? 'chart/todayPayments'
        : 'chart/monthyPayments'
    )
  }

  if (loading) {
    return (
      <Card>
        <CardHeader
          title={`Resumen del ${
            reportType === REPORT_TYPES.DAILY ? 'día' : 'mes'
          }`}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
              <Skeleton height={350} width='100%' />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Skeleton height={350} width='100%' />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Skeleton height={350} width='100%' />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  if (error) {
    return (
      <Typography variant='body2' gutterBottom>
        {error}
      </Typography>
    )
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(postIsError && postError)}
        type='error'
        message={
          typeof postError === 'string'
            ? postError
            : postError && postError.message
        }
      />

      <Card>
        <CardHeader
          title={`Resumen del ${
            reportType === REPORT_TYPES.DAILY ? 'día' : 'mes'
          }`}
          subheader={
            data && data.chartData
              ? `Información de los usuarios y los cobros que han realizado durante el ${
                  reportType === REPORT_TYPES.DAILY ? 'día' : 'mes'
                }`
              : `Información de mis cobros del${
                  reportType === REPORT_TYPES.DAILY ? 'día' : 'mes'
                }`
          }
        />
        <CardContent>
          <Box mb={3}>
            <TextField
              select
              fullWidth={false}
              label='Tipo'
              onChange={handleOnChangeLocation}
              value={reportType}
            >
              {Object.keys(REPORT_TYPES).map((key: string) => {
                const value = REPORT_TYPES[key]

                return (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                )
              })}
            </TextField>
          </Box>

          <Grid container spacing={2}>
            {data && data.chartData && (
              <Grid item md={3} sm={6} xs={12}>
                <Card>
                  <CardHeader
                    title={`Total: ${formatAmount(data.totalAmount)}`}
                    subheader={`Cantidad de pagos: ${data.totalPayments}`}
                  />
                  {data.totalAmount > 0 && (
                    <CardContent>
                      <VictoryPie
                        colorScale={[primaryColor, secondaryColor]}
                        padding={125}
                        data={data.chartData}
                        labels={({ datum }: { datum: Object }) =>
                          `${datum.x}: ${formatAmount(datum.y)}`
                        }
                      />
                    </CardContent>
                  )}
                  <CardActions>
                    <Tooltip title='Bloquea a todos los cobradores' arrow>
                      <Button
                        variant='contained'
                        fullWidth
                        color='secondary'
                        disabled={postLoading}
                        onClick={() =>
                          post('user/blockAllRoots', {}, () => refetch())
                        }
                      >
                        Bloquear
                      </Button>
                    </Tooltip>
                    <Tooltip title='Desbloquea a todos los cobradores' arrow>
                      <Button
                        variant='contained'
                        fullWidth
                        color='primary'
                        disabled={postLoading}
                        onClick={() =>
                          post('user/unblockAllRoots', {}, () => refetch())
                        }
                      >
                        Desbloquear
                      </Button>
                    </Tooltip>
                  </CardActions>
                </Card>
              </Grid>
            )}

            {data &&
              data.users &&
              data.users.map &&
              data.users.map((user: Object) => {
                return (
                  <Grid key={user.id} item md={3} sm={6} xs={12}>
                    <Card>
                      <CardHeader
                        title={`${user.name}: ${formatAmount(
                          user.totalAmount
                        )}`}
                        subheader={`Estado: ${user.userStatus.name} - Total de pagos ${user.totalPayments}`}
                      />
                      {user.totalAmount > 0 && (
                        <CardContent>
                          <VictoryPie
                            colorScale={[primaryColor, secondaryColor]}
                            padding={125}
                            data={user.chartData}
                            labels={({ datum }: { datum: Object }) =>
                              `${datum.x}: ${formatAmount(datum.y)}`
                            }
                          />
                        </CardContent>
                      )}

                      {data && data.chartData && (
                        <CardActions>
                          {user.userStatus.type === 'ACTIVE' && (
                            <Tooltip title={`Bloquea a ${user.name}`} arrow>
                              <Button
                                variant='contained'
                                fullWidth
                                color='secondary'
                                disabled={postLoading}
                                onClick={() =>
                                  post('user/blockById', { id: user.id }, () =>
                                    refetch()
                                  )
                                }
                              >
                                Bloquear
                              </Button>
                            </Tooltip>
                          )}
                          {user.userStatus.type === 'BLOCKED' && (
                            <Tooltip title={`Desbloquea a ${user.name}`} arrow>
                              <Button
                                variant='contained'
                                fullWidth
                                color='primary'
                                disabled={postLoading}
                                onClick={() =>
                                  post(
                                    'user/unblockById',
                                    { id: user.id },
                                    () => refetch()
                                  )
                                }
                              >
                                Desbloquear
                              </Button>
                            </Tooltip>
                          )}
                        </CardActions>
                      )}
                    </Card>
                  </Grid>
                )
              })}
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default Welcome
