// @flow
import React from 'react'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

function PaperComponent({ children }: { children: React.Node }) {
  const classes = useStyles()
  return <Paper className={classes.root}>{children}</Paper>
}

const useStyles = makeStyles({
  root: {
    padding: 10
  }
})

export default PaperComponent
