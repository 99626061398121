import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { useLocation, useParams, useHistory } from 'react-router-dom'

import { TextField } from 'components'
import { useGet } from 'hooks'
import { auditUtils } from 'utils'

function Details() {
  const [data, setData] = useState(null)
  const { state, pathname } = useLocation()
  const { id } = useParams()
  const history = useHistory()
  const { loading, error, data: paymentData } = useGet(
    state ? null : `audit/${id}`
  )

  useEffect(() => {
    if (!state) {
      return
    }

    const stateCopy = { ...state }

    if (stateCopy && stateCopy.tableData) {
      delete stateCopy.tableData
    }

    setData(stateCopy)
  }, [state])

  useEffect(() => {
    if (!paymentData) {
      return
    }

    history.replace({ pathname, state: paymentData })
  }, [paymentData, history, pathname])

  if (
    (loading || (!paymentData && !data) || (paymentData && !data)) &&
    !error
  ) {
    return (
      <Card>
        <CardHeader title={<Skeleton height={50} width='100%' />} />
        <CardContent>
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
        </CardContent>
      </Card>
    )
  }

  if (error) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {error}
        </Typography>
      </Box>
    )
  }

  if (!data) {
    return (
      <Card>
        <CardHeader title={`No existe una auditoria con el id ${id}`} />
      </Card>
    )
  }

  const { name } = auditUtils.getTableInfo(data?.tableName)

  return (
    <Card>
      <CardHeader title='Auditoria' />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={4} xs={12}>
            <TextField label='Id' readOnly value={data?.id} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField label='Tabla' readOnly value={name} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField label='Id de la tabla' readOnly value={data?.rowId} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField label='Usuario' readOnly value={data?.user?.name} />
          </Grid>
          <Grid sm={8} xs={12} />

          <Grid item sm={6} xs={12}>
            <Typography>Antes</Typography>
            <pre>{JSON.stringify(data?.originValues, null, 2)}</pre>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography>Despues</Typography>
            <pre>{JSON.stringify(data?.newValues, null, 2)}</pre>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Details
