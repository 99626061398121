// @flow
import React from 'react'
import { Switch } from 'react-router-dom'

import List from './List'
import Details from './Details'
import NoMatch from 'screens/NoMatch'
import { CustomRoute } from 'components'

function AuditsRouter() {
  return (
    <Switch>
      <CustomRoute
        exact
        path='/inicio/auditorias/mostrar'
        component={List}
        isPrivate
        isProtected
      />

      <CustomRoute
        exact
        path='/inicio/auditorias/detalle/:id'
        component={Details}
        isPrivate
        isProtected
      />

      <CustomRoute component={NoMatch} />
    </Switch>
  )
}

export default AuditsRouter
