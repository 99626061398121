// @flow
import * as yup from 'yup'
import moment from 'moment'

import { regex } from 'utils'

const initialValues = {
  amount: '',
  interestAmount: '',
  capitalAmount: '',
  penaltyAmount: '',
  currentBalanceAmount: '',
  notes: '',
  payday: moment()
}

const schema = yup.object().shape({
  amount: yup
    .string()
    .required('El monto es obligatorio.')
    .matches(regex.onlyNumbers, 'El monto solo debe contener numeros.'),
  interestAmount: yup
    .string()
    .required('El monto es obligatorio.')
    .matches(regex.onlyNumbers, 'El monto solo debe contener numeros.'),
  capitalAmount: yup
    .string()
    .required('El monto es obligatorio.')
    .matches(regex.onlyNumbers, 'El monto solo debe contener numeros.'),
  penaltyAmount: yup
    .string()
    .required('El monto es obligatorio.')
    .matches(regex.onlyNumbers, 'El monto solo debe contener numeros.'),
  currentBalanceAmount: yup
    .string()
    .required('El monto es obligatorio.')
    .matches(regex.onlyNumbers, 'El monto solo debe contener numeros.'),
  notes: yup.string(),
  payday: yup.date().required('La fecha del proximo pago es obligatorio.')
})

export default {
  initialValues,
  schema
}
