// @flow
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

function useMatchSizes() {
  const theme = useTheme()

  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'))

  return {
    xsDown,
    mdDown,
    xlDown
  }
}

export default useMatchSizes
