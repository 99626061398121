// @flow
import React from 'react'
import { Switch } from 'react-router-dom'

import List from './List'
import Today from './Today'
import Personal from './Personal'
import Details from './Details'
import NoMatch from 'screens/NoMatch'
import { CustomRoute } from 'components'

function PaymentsRouter() {
  return (
    <Switch>
      <CustomRoute
        exact
        path='/inicio/pagos/mostrar'
        component={List}
        isPrivate
        isProtected
      />
      <CustomRoute
        exact
        path='/inicio/pagos/mostrar/hoy'
        component={Today}
        isPrivate
        isProtected
      />
      <CustomRoute
        exact
        path='/inicio/pagos/mostrar/personales'
        component={Personal}
        isPrivate
      />
      <CustomRoute
        exact
        path='/inicio/pagos/detalle/:id'
        component={Details}
        isPrivate
      />
      <CustomRoute component={NoMatch} />
    </Switch>
  )
}

export default PaymentsRouter
