// @flow
import React from 'react'
import MaterialTable from 'material-table'
import { useTheme } from '@material-ui/core/styles'

import { useViewport } from 'hooks'

function Table({
  filtering = true,
  ...props
}: {
  name: string,
  filtering?: Boolean,
  props?: Object
}) {
  const theme = useTheme()
  const { vh } = useViewport()
  const maxBodyHeight = vh(70)

  return (
    <MaterialTable
      title=''
      localization={{
        toolbar: {
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar',
          exportTitle: 'Exportar',
          exportAriaLabel: 'Exportar',
          exportName: 'Exportar'
        },
        pagination: {
          labelRowsSelect: 'Filas',
          firstAriaLabel: ' Primer página',
          firstTooltip: 'Primer página',
          lastAriaLabel: 'Ultima página',
          lastTooltip: 'Ultima página',
          previousAriaLabel: 'Página anterior',
          previousTooltip: 'Página anterior',
          nextAriaLabel: ' Página siguiente',
          nextTooltip: ' Página siguiente'
        },
        body: {
          emptyDataSourceMessage: `No hay datos para mostrar`
        },
        header: {
          actions: ''
        }
      }}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 50, 100, 200],
        filtering,
        exportButton: false,
        // exportCsv: (columns: Array<Object>, data: Array<Object>) => {
        //   if (isAdmin) {
        //     csvUtils.exportCsv(columns, data, name)
        //   }
        // },
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          position: 'sticky',
          top: 0
        },
        maxBodyHeight
      }}
      {...props}
    />
  )
}

export default Table
