// @flow
import { useState, useReducer, useEffect } from 'react'

import { fetch } from 'api'

const useGetData = (initialUrl?: string = null) => {
  const [url, setUrl] = useState(initialUrl)
  const [state, setState] = useReducer(
    (state: Object, newState: Object) => ({ ...state, ...newState }),
    {
      loading: false,
      error: null,
      data: null
    }
  )

  const get = async (url: string, callback?: Function = null) => {
    try {
      setState({
        loading: true,
        error: null
      })

      const response = await fetch.get(url)

      setState({
        loading: false,
        data: response
      })

      if (callback && typeof callback === 'function') {
        callback(response)
      }
    } catch (error) {
      setState({
        loading: false,
        error
      })
    }
  }

  const refetch = () => get(url)

  useEffect(() => {
    if (!url) {
      return
    }

    get(url)
  }, [url])

  return {
    ...state,
    get,
    setUrl,
    refetch
  }
}

export default useGetData
