// @flow
import { getHeaders } from './config'
import { constants } from 'config'

const get = (endpoint: string) =>
  new Promise(async (resolve: Function, reject: Function) => {
    try {
      const method = 'GET'
      const request = await window.fetch(`${constants.baseUrl}/${endpoint}`, {
        method,
        headers: getHeaders(endpoint, method)
      })

      const response = await request.json()

      if (!request.ok) {
        throw new Error(response.message)
      }

      resolve(response)
    } catch (err) {
      reject(err.message)
    }
  })

const post = (endpoint: string, data: Object) =>
  new Promise(async (resolve: Function, reject: Function) => {
    try {
      const method = 'POST'
      const request = await window.fetch(`${constants.baseUrl}/${endpoint}`, {
        method,
        body: JSON.stringify(data),
        headers: getHeaders(endpoint, method)
      })

      const response = await request.json()

      if (!request.ok) {
        throw new Error(response.message)
      }

      resolve(response)
    } catch (err) {
      reject(err.message)
    }
  })

const fileUpload = (endpoint: string, file: Object, method: string = 'PUT') =>
  new Promise(async (resolve: Function, reject: Function) => {
    try {
      const response = await window.fetch(endpoint, {
        method,
        body: file
      })

      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`)
      }

      resolve(response)
    } catch (err) {
      reject(err.message)
    }
  })

export default {
  get,
  post,
  fileUpload
}
