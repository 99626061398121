// @flow
import React, { useEffect } from 'react'
import {
  Box,
  Grid,
  Typography,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
  Tooltip,
  Link as MuiLink
} from '@material-ui/core'
import { Payment as PaymentIcon } from '@material-ui/icons'
import { useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'
import { MTableToolbar } from 'material-table'

import { getFee, formatAmount, auth } from 'utils'
import { constants } from 'config'
import { Table, Link } from 'components'
import { useGet, useMatchSizes, useQueryParameters } from 'hooks'

const getUrls = (userId: number) => {
  if (userId) {
    return {
      0: `loan/latePayment/${userId}`,
      1: `loan/todayPayment/${userId}`,
      2: `loan/all/${userId}`,
      3: `loan/penaltyFee/${userId}`,
      4: `loan/defaulter/${userId}`
    }
  } else {
    return {
      0: 'loan/latePayment',
      1: 'loan/todayPayment',
      2: 'loan/all',
      3: 'loan/penaltyFee',
      4: 'loan/defaulter'
    }
  }
}

function ListLoans() {
  const queryParameters = useQueryParameters()
  const location = useLocation()
  const history = useHistory()
  const userId = queryParameters.get('userId')
  const tabSelected = parseInt(queryParameters.get('tabSelected') || 1)
  const url = getUrls(userId)[tabSelected]
  const { data, loading, error, setUrl } = useGet(url)
  const { xsDown } = useMatchSizes()
  const isAdminOrSupervisor = auth.isAdminOrSupervisor()

  useEffect(() => {
    setUrl(url)
  }, [setUrl, url])

  const handleTabChange = (_: Object, newValue: number) => {
    queryParameters.set('tabSelected', newValue)
    history.push(`${location.pathname}?${queryParameters.toString()}`)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Typography variant='h6' noWrap>
        Mostrar Préstamos
      </Typography>
      <Grid container justify='center' spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Tabs
              value={tabSelected}
              onChange={handleTabChange}
              indicatorColor='primary'
              textColor='primary'
              centered
              scrollButtons='auto'
              variant={xsDown ? 'scrollable' : 'standard'}
            >
              <Tab label='Atrás' />
              <Tab label='Hoy' />
              <Tab label='Todos' />
              <Tab label='Penalización' />
              <Tab label='Morosos' />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Box display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          )}

          {error && (
            <Box display='flex' justifyContent='center'>
              <Typography color='secondary' variant='body2'>
                {error}
              </Typography>
            </Box>
          )}
          {!loading && !error && (
            <Table
              name='préstamos'
              components={{
                Toolbar: function Toolbar(props: Object) {
                  const totalAmount =
                    (props &&
                      props.data &&
                      props.data.reduce(
                        (sum: number, item: Object) => sum + item.balance,
                        0
                      )) ||
                    0

                  return (
                    <>
                      <MTableToolbar {...props} />

                      {isAdminOrSupervisor && (
                        <Box display='flex'>
                          <Paper>
                            <Typography variant='body2'>{`Total: ${formatAmount(
                              totalAmount
                            )}`}</Typography>
                          </Paper>
                        </Box>
                      )}
                    </>
                  )
                },
                Action: function ActionComponent({ data }: { data: Object }) {
                  const disabled = data.loanStatus.status === constants.FINISHED

                  return disabled ? (
                    <PaymentIcon color={disabled ? 'disabled' : 'primary'} />
                  ) : (
                    <Link
                      to={{
                        pathname: `/inicio/prestamos/pagar/${data.id}`,
                        state: data
                      }}
                      // target='_blank'
                    >
                      <Tooltip title='Pagar'>
                        <PaymentIcon
                          color={disabled ? 'disabled' : 'primary'}
                        />
                      </Tooltip>
                    </Link>
                  )
                }
              }}
              actions={[
                (rowData: Object) => ({
                  icon: 'payment',
                  tooltip: 'Pagar',
                  onClick: () => {},
                  disabled: rowData.loanStatus.status === constants.FINISHED
                })
              ]}
              columns={[
                {
                  title: 'Id',
                  field: 'id',
                  render: function Column(rowData: Object) {
                    return (
                      <Link
                        to={{
                          pathname: `/inicio/prestamos/detalle/${rowData.id}`,
                          state: rowData
                        }}
                      >
                        <Typography variant='body2' color='primary'>
                          {rowData.id}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Cliente',
                  field: 'client.name',
                  render: function ClientNameRow(rowData: Object) {
                    return (
                      <Link to={`/inicio/clientes/perfil/${rowData.client.id}`}>
                        <Typography variant='body2' color='primary'>
                          {rowData.client.name}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Teléfono',
                  field: 'client.phoneNumber',
                  hidden: xsDown,
                  render: function PhoneNumberRow(rowData: Object) {
                    return (
                      <MuiLink
                        target='_blank'
                        rel='noreferrer'
                        href={`https://wa.me/506${rowData.client.phoneNumber}`}
                      >
                        <Typography variant='body2' color='primary'>
                          {rowData.client.phoneNumber}
                        </Typography>
                      </MuiLink>
                    )
                  }
                },
                {
                  title: 'Tipo',
                  field: 'loanType.name',
                  hidden: xsDown
                },
                {
                  title: 'Interes %',
                  field: 'interestPercentage',
                  customFilterAndSearch: (term: string, rowData: Object) => {
                    return (
                      rowData.interestPercentage.toString() === term.toString()
                    )
                  },
                  hidden: xsDown,
                  render: function InterestPercentageRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {`${rowData.interestPercentage}%`}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Capital %',
                  field: 'capitalPercentage',
                  customFilterAndSearch: (term: string, rowData: Object) => {
                    return (
                      rowData.capitalPercentage.toString() === term.toString()
                    )
                  },
                  hidden: xsDown,
                  render: function CapitalPercentageRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {`${rowData.capitalPercentage}%`}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Periodicidad',
                  field: 'loanPeriodicity.name',
                  hidden: xsDown
                },
                {
                  title: 'Cuota',
                  field: 'amount',
                  render: function AmountRow(rowData: Object) {
                    const { expectedAmount } = getFee(
                      rowData.loanType.type === constants.FIXED
                        ? rowData.balance
                        : rowData.amount,
                      rowData.interestPercentage,
                      rowData.capitalPercentage,
                      rowData.loanPeriodicity.times,
                      rowData.penaltyFee
                    )

                    return (
                      <Typography variant='body2'>
                        {formatAmount(expectedAmount)}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Balance',
                  field: 'balance',
                  render: function AmountRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.balance)}
                      </Typography>
                    )
                  },
                  hidden: xsDown
                },
                {
                  title: 'Monto',
                  field: 'amount',
                  render: function AmountRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.amount)}
                      </Typography>
                    )
                  },
                  hidden: xsDown
                },
                {
                  title: 'Penalización',
                  field: 'penaltyFee',
                  render: function PenaltyFeeRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.penaltyFee)}
                      </Typography>
                    )
                  },
                  hidden: xsDown
                },
                {
                  title: 'Fecha de pago',
                  field: 'payday',
                  customFilterAndSearch: (term: string, rowData: Object) => {
                    const value = moment(rowData.payday).format(
                      'dddd, DD/MM/YYYY'
                    )

                    return value.toUpperCase().includes(term.toUpperCase())
                  },
                  render: function PaydayRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {moment(rowData.payday).format('dddd, DD/MM/YYYY')}
                      </Typography>
                    )
                  },
                  hidden: xsDown
                },
                {
                  title: 'Días de atraso',
                  field: 'daysLate',
                  customFilterAndSearch: (term: string, rowData: Object) => {
                    const paydate = moment(rowData.payday)
                    const daysLate = moment().diff(paydate, 'days')
                    const isFinished =
                      rowData.loanStatus.status === constants.FINISHED

                    const value = isFinished || daysLate < 0 ? 0 : daysLate

                    return value.toString() === term.toString()
                  },
                  customSort: (a: Object, b: Object) => {
                    return (
                      moment().diff(moment(a.payday), 'days') -
                      moment().diff(moment(b.payday), 'days')
                    )
                  },
                  render: function DaysLateRow(rowData: Object) {
                    const paydate = moment(rowData.payday)
                    const daysLate = moment().diff(paydate, 'days')
                    const isFinished =
                      rowData.loanStatus.status === constants.FINISHED

                    const value = isFinished || daysLate < 0 ? 0 : daysLate

                    return <Typography variant='body2'>{value}</Typography>
                  },
                  hidden: xsDown
                },
                {
                  title: 'Fecha de creación',
                  field: 'createdAt',
                  customFilterAndSearch: (term: string, rowData: Object) => {
                    const value = moment(rowData.createdAt).format('DD/MM/YYYY')

                    return value.toUpperCase().includes(term.toUpperCase())
                  },
                  render: function CreatedAtRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {moment(rowData.createdAt).format('DD/MM/YYYY')}
                      </Typography>
                    )
                  },
                  hidden: xsDown
                },
                {
                  title: 'Cobrador',
                  field: 'user.name',
                  hidden: xsDown
                },
                {
                  title: 'Estado',
                  field: 'loanStatus.name',
                  hidden: xsDown
                }
              ]}
              data={data}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ListLoans
