// @flow
import React from 'react'
import { Switch } from 'react-router-dom'

import Clients from 'screens/Clients'
import Loans from 'screens/Loans'
import Payments from 'screens/Payments'
import Users from 'screens/Users'
import Profile from 'screens/Profile'
import Audits from 'screens/Audits'
import Welcome from 'screens/Welcome'
import NoMatch from 'screens/NoMatch'
import { CustomRoute, Drawer } from 'components'

function Home() {
  return (
    <Drawer>
      <Switch>
        <CustomRoute path='/inicio/clientes' component={Clients} isPrivate />
        <CustomRoute path='/inicio/perfil' component={Profile} isPrivate />
        <CustomRoute path='/inicio/prestamos' component={Loans} isPrivate />
        <CustomRoute path='/inicio/pagos' component={Payments} isPrivate />
        <CustomRoute
          path='/inicio/usuarios'
          component={Users}
          isPrivate
          isProtected
        />
        <CustomRoute
          path='/inicio/auditorias'
          component={Audits}
          isPrivate
          isProtected
        />
        <CustomRoute exact path='/inicio' component={Welcome} isPrivate />
        <CustomRoute component={NoMatch} />
      </Switch>
    </Drawer>
  )
}

export default Home
