// @flow
import * as yup from 'yup'

import { regex } from 'utils'

const initialValues = {
  amount: '',
  notes: '',
  isTypeToCapital: false,
  isTypeField: false,
  addNotesField: false
}

const schema = yup.object().shape({
  amount: yup
    .string()
    .required('El monto es obligatorio.')
    .matches(regex.onlyNumbers, 'El monto solo debe contener numeros.'),
  notes: yup.string(),
  isTypeToCapital: yup.boolean(),
  isTypeField: yup.boolean(),
  addNotesField: yup.boolean()
})

export default {
  initialValues,
  schema
}
