// @flow
import React, { Fragment, useState } from 'react'
import {
  Divider,
  Icon,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { auth } from 'utils'
import { data } from 'api'

const Options = ({
  history,
  onCloseDrawer = () => {}
}: {
  history: Object,
  onCloseDrawer: Function
}) => {
  const classes = useStyles()
  const { id, userName } = auth.decodedToken()
  const { options, initialState } = data.getDrawer(id)
  const [open, setOpen] = useState(initialState)

  const handleOpen = (key: number) => {
    setOpen({
      ...open,
      [key]: !open[key]
    })
  }

  return (
    <>
      <div className={classes.toolbar} />
      <Divider />
      <List
        component='nav'
        subheader={
          <ListSubheader component='div' id='nested-list-subheader'>
            {userName}
          </ListSubheader>
        }
      >
        {options.map((option: Object) => (
          <Fragment key={`Menu-${option.id}`}>
            <ListItem
              button
              onClick={() => {
                if (!option.subMenu) {
                  if (option.path === '/') {
                    auth.cleanToken()
                  }

                  history.push(option.path)
                  onCloseDrawer()
                }

                handleOpen(option.key)
              }}
            >
              <ListItemIcon>
                <Icon>{option.iconName}</Icon>
              </ListItemIcon>
              <ListItemText primary={option.displayName} />
            </ListItem>
            {option.subMenu &&
              option.subMenu.map((item: Object) => (
                <Collapse
                  key={`Menu-${option.id}-subMenu-${item.id}`}
                  in={open[option.key]}
                  timeout='auto'
                  unmountOnExit
                >
                  <List component='div' disablePadding>
                    <ListItem
                      component={Link}
                      to={item.path}
                      className={classes.nested}
                      onClick={() => {
                        onCloseDrawer()
                      }}
                    >
                      <ListItemIcon>
                        <Icon>{item.iconName}</Icon>
                      </ListItemIcon>
                      <ListItemText primary={item.displayName} />
                    </ListItem>
                  </List>
                </Collapse>
              ))}
          </Fragment>
        ))}
      </List>
    </>
  )
}

const useStyles = makeStyles((theme: Object) => ({
  toolbar: theme.mixins.toolbar,
  nested: {
    marginLeft: theme.spacing(4),
    color: theme.palette.text.primary
  }
}))

export default withRouter(Options)
