// @flow
import React from 'react'
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box
} from '@material-ui/core/'
import { Menu } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import { Link } from 'components'

const Header = ({ onClick }: { onClick: Function }) => {
  const classes = useStyles()

  return (
    <AppBar position='fixed'>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='Open drawer'
          edge='start'
          onClick={onClick}
          className={classes.menuButton}
        >
          <Menu />
        </IconButton>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          className={classes.content}
        >
          <Link to='/'>
            <Typography variant='h6' noWrap className={classes.linkText}>
              Inicio
            </Typography>
          </Link>
          {/* <Typography variant='h6' noWrap>
            Bienvenido {userName}
          </Typography> */}
          <Typography variant='h6' noWrap>
            {moment().format('DD/MM/YYYY')}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme: Object) => {
  return {
    menuButton: {
      marginRight: theme.spacing(2)
    },
    content: {
      flex: 1
    },
    linkText: {
      color: theme.palette.common.white
    }
  }
})

export default Header
