// @flow
import { useReducer } from 'react'

import { fetch } from 'api'
import useSlideMessage from '../useSlideMessage'

const usePost = () => {
  const [state, setState] = useReducer(
    (state: Object, newState: Object) => ({ ...state, ...newState }),
    {
      loading: false,
      error: null,
      data: null
    }
  )

  const {
    isVisible: isSucceded,
    setIsVisible: setIsSucceded
  } = useSlideMessage()
  const { isVisible: isError, setIsVisible: setIsError } = useSlideMessage()

  const post = async (
    url: string,
    data: Object,
    callback?: Function = null
  ) => {
    try {
      setState({
        loading: true,
        error: null,
        data: null
      })

      const response = await fetch.post(url, data)

      setState({
        data: response,
        loading: false
      })

      setIsSucceded(true)

      if (callback && typeof callback === 'function') {
        callback(response)
      }
    } catch (error) {
      setState({
        loading: false,
        error
      })

      setIsError(true)
    }
  }

  return {
    ...state,
    post,
    isSucceded,
    isError
  }
}

export default usePost
