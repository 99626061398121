// @flow
const getFee = (
  amount: number,
  interestPercentage: number,
  capitalPercentage: number,
  periodicityTimes: number,
  penalty: number
) => {
  const percentageTotal = (interestPercentage + capitalPercentage) / 100
  const weekFee = amount * percentageTotal
  const periodicityFee = weekFee * periodicityTimes
  const expectedAmount = periodicityFee + penalty

  const interestAmount = amount * (interestPercentage / 100) * periodicityTimes
  const capitalAmount = amount * (capitalPercentage / 100) * periodicityTimes

  return {
    expectedAmount: Math.round(expectedAmount) || null,
    periodicityFee: Math.round(periodicityFee) || null,
    interestAmount: Math.round(interestAmount) || null,
    capitalAmount: Math.round(capitalAmount) || null
  }
}

export default getFee
