// @flow
import { auth } from 'utils'

export const getHeaders = (endpoint: string, method: string) => {
  if (method === 'GET') {
    return {
      Authorization: auth.getToken()
    }
  }

  if (method === 'POST' && endpoint !== 'login') {
    return {
      'Content-Type': 'application/json',
      Authorization: auth.getToken()
    }
  }

  return {
    'Content-Type': 'application/json'
  }
}
