import React from 'react'
import { TextField } from '@material-ui/core'

function BaseTextField({
  readOnly = false,
  ...props
}: {
  readOnly: Boolean,
  props?: Object
}) {
  return (
    <TextField
      variant='outlined'
      margin='normal'
      fullWidth
      autoComplete='off'
      InputProps={{
        readOnly
      }}
      {...props}
    />
  )
}

export default BaseTextField
