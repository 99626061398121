import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { aws } from 'config'
import { useMatchSizes } from 'hooks'

function FileUpload({ value }: { value: string }) {
  const classes = useStyles()
  const { xsDown } = useMatchSizes()

  if (!value) {
    return null
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      className={classes.fileContainer}
    >
      <img
        src={`${aws.s3.url}${value}`}
        alt={value}
        width={xsDown ? '100%' : '50%'}
        height='100%'
      />
    </Box>
  )
}

const useStyles = makeStyles({
  fileContainer: {
    height: 350
  }
})

FileUpload.propTypes = {
  value: PropTypes.string,
  spacing: PropTypes.bool
}

export default FileUpload
