// @flow
import React from 'react'
import { Drawer } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'

import Options from './Options'

const DrawerOptions = ({
  isOpen,
  handleDrawerToggle
}: {
  isOpen: boolean,
  handleDrawerToggle: Function
}) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <nav aria-label='Mailbox folders'>
      <Drawer
        variant='temporary'
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={isOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true
        }}
      >
        <Options onCloseDrawer={handleDrawerToggle} />
      </Drawer>
    </nav>
  )
}

const useStyles = makeStyles({
  drawerPaper: {
    width: 240
  }
})

export default DrawerOptions
