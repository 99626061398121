// @flow
import React from 'react'
import { Switch } from 'react-router-dom'

import Add from './Add'
import List from './List'
import Profile from './Profile'
import NoMatch from 'screens/NoMatch'
import { CustomRoute } from 'components'

function UsersRouter() {
  return (
    <Switch>
      <CustomRoute
        exact
        path='/inicio/usuarios/agregar'
        component={Add}
        isPrivate
        isProtected
      />
      <CustomRoute
        exact
        path='/inicio/usuarios/mostrar'
        component={List}
        isPrivate
        isProtected
      />
      <CustomRoute
        exact
        path='/inicio/usuarios/perfil/:id'
        component={Profile}
        isPrivate
        isProtected
      />
      <CustomRoute component={NoMatch} />
    </Switch>
  )
}

export default UsersRouter
