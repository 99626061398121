// @flow
import React from 'react'

//Components.
import Header from './Header'
import DrawerOptions from './DrawerOptions'
import Content from './Content'

function Drawer({ children }: { children: React.Node }) {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <>
      <Header onClick={handleDrawerToggle} />
      <DrawerOptions
        isOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Content>{children}</Content>
    </>
  )
}

export default Drawer
