// @flow
import React from 'react'
import {
  Typography,
  CircularProgress,
  Box,
  Link as MuiLink
} from '@material-ui/core'

import { useGet, useMatchSizes } from 'hooks'
import { Table, Link } from 'components'

function ListUsers() {
  const { data, loading, error } = useGet('user/all')

  const { xsDown } = useMatchSizes()

  if (loading) {
    return (
      <Box display='flex' justifyContent='center'>
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {error}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Typography align='center' variant='h6'>
        Lista de Usuarios
      </Typography>

      <Table
        name='usuarios'
        columns={[
          {
            title: 'Id',
            field: 'id',
            render: function Column(rowData: Object) {
              return (
                <Link
                  to={{
                    pathname: `/inicio/usuarios/perfil/${rowData.id}`,
                    state: rowData
                  }}
                >
                  <Typography variant='body2' color='primary'>
                    {rowData.id}
                  </Typography>
                </Link>
              )
            }
          },
          {
            title: 'Nombre',
            field: 'name'
          },
          {
            title: 'Nombre de Usuario',
            field: 'userName',
            hidden: xsDown
          },
          {
            title: 'Teléfono',
            field: 'phoneNumber',
            disableClick: true,
            render: function PhoneNumberRow(rowData: Object) {
              return (
                <MuiLink
                  target='_blank'
                  rel='noreferrer'
                  href={`https://wa.me/506${rowData.phoneNumber}`}
                >
                  <Typography variant='body2' color='primary'>
                    {rowData.phoneNumber}
                  </Typography>
                </MuiLink>
              )
            }
          },
          {
            title: 'Cédula',
            field: 'idNumber',
            hidden: xsDown
          },
          {
            title: 'Tipo de usuario',
            field: 'userType.name',
            hidden: xsDown
          },
          {
            title: 'Estado',
            field: 'userStatus.name',
            hidden: xsDown
          }
        ]}
        data={data}
      />
    </>
  )
}

export default ListUsers
