// @flow
import jwt from 'jsonwebtoken'

import { constants } from 'config'

const config = {
  key: 'token',
  publicKey: process.env.REACT_APP_PUBLIC_KEY || 'coco'
}

const setToken = (token: Object) =>
  window.localStorage.setItem(config.key, token)

const getToken = () => window.localStorage.getItem(config.key) || ''

const cleanToken = () => window.localStorage.setItem(config.key, '')

const decodedToken = () =>
  jwt.decode(getToken().replace('Bearer ', ''), config.publicKey)

const isAdmin = () => {
  const {
    userType: { type }
  } = decodedToken()
  return Boolean(type === constants.ADMIN)
}

const isAdminOrSupervisor = () => {
  const {
    userType: { type }
  } = decodedToken()
  return (
    Boolean(type === constants.ADMIN) || Boolean(type === constants.SUPERVISOR)
  )
}

const isValidToken = () => {
  const token = getToken()

  if (token) {
    try {
      jwt.verify(token.replace('Bearer ', ''), config.publicKey)
      return true
    } catch (err) {
      return false
    }
  }

  return false
}

export default {
  setToken,
  getToken,
  cleanToken,
  isValidToken,
  decodedToken,
  isAdmin,
  isAdminOrSupervisor
}
