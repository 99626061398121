// @flow
import React from 'react'
import { Button as BaseButton } from '@material-ui/core'

function Button({ ...props }: { props?: Object }) {
  return (
    <BaseButton
      type='submit'
      fullWidth
      variant='contained'
      color='primary'
      {...props}
    />
  )
}

export default Button
