// @flow
import { useState, useEffect } from 'react'

const useSlideMessage = (initialState: boolean = false) => {
  const [isVisible, setIsVisible] = useState(initialState)

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsVisible(false)
      }, 2000)
    }
  }, [isVisible])

  return {
    isVisible,
    setIsVisible
  }
}

export default useSlideMessage
