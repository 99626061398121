// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

function Content({ children }: { children: React.Node }) {
  const classes = useStyles()
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      {children}
    </main>
  )
}

const useStyles = makeStyles((theme: Object) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar
}))

export default Content
