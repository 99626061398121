const formatAmount = (
  amount: number,
  format: string = 'es-419',
  style: string = 'decimal',
  currency: string = 'CRC',
  currencyDisplay: string = 'symbol',
  currencySymbol: string = '₡'
) =>
  `${currencySymbol}${new Intl.NumberFormat(format, {
    style,
    currency,
    currencyDisplay
  }).format(amount)}`

export default formatAmount
