// @flow
import React from 'react'
import { Snackbar, Slide } from '@material-ui/core'

const colors = {
  success: '#2ecc71',
  error: '#e74c3c'
}

function BaseSnackbar({
  isVisible,
  message = 'Agregado correctamente :)',
  type = 'success'
}: {
  isVisible: boolean,
  message: string,
  type: 'success' | 'error'
}) {
  return (
    <Snackbar
      variant='success'
      open={isVisible}
      TransitionComponent={(props: Object) => (
        <Slide {...props} direction='up' />
      )}
      ContentProps={{
        style: { backgroundColor: colors[type] }
      }}
      message={<span id='message-id'>{message}</span>}
    />
  )
}

export default BaseSnackbar
