// @flow
import React from 'react'
import { Typography, Box } from '@material-ui/core'

import { useGet } from 'hooks'
import { Table, Link } from 'components'
import { auditUtils } from 'utils'

function ListAudits() {
  const { data, loading, error } = useGet('audit/all')

  if (error) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {error}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Typography align='center' variant='h6'>
        Lista de auditoria
      </Typography>

      <Table
        name='auditorias'
        isLoading={loading}
        data={data || []}
        columns={[
          {
            title: 'Id',
            field: 'id',
            render: function Column(rowData: Object) {
              return (
                <Link
                  to={{
                    pathname: `/inicio/auditorias/detalle/${rowData.id}`,
                    state: rowData
                  }}
                >
                  <Typography variant='body2' color='primary'>
                    {rowData.id}
                  </Typography>
                </Link>
              )
            }
          },
          {
            title: 'Tabla',
            field: 'tableName',
            render: function TableNameRow(rowData: Object) {
              const { name } = auditUtils.getTableInfo(rowData?.tableName)

              return <Typography variant='body2'>{name}</Typography>
            }
          },
          {
            title: 'Id de la tabla',
            field: 'rowId',
            render: function Column(rowData: Object) {
              const { path } = auditUtils.getTableInfo(rowData?.tableName)
              const pathname = `/inicio/${path}/${
                rowData?.tableName === 'clients' ? 'perfil' : 'detalle'
              }/${rowData.rowId}`

              return (
                <Link
                  to={{
                    pathname
                  }}
                >
                  <Typography variant='body2' color='primary'>
                    {rowData.rowId}
                  </Typography>
                </Link>
              )
            }
          },
          {
            title: 'Usuario',
            field: 'user.name'
          }
        ]}
      />
    </>
  )
}

export default ListAudits
