// @flow
import * as yup from 'yup'

const initialValues = {
  userName: '',
  password: ''
}

const schema = yup.object().shape({
  userName: yup.string().required('El nombre de usuario es obligatorio.'),
  password: yup
    .string()
    .required('La contraseña es obligatoria.')
    .min(6, 'El mínimo de caracteres para la contraseña son 6.')
})

export default {
  initialValues,
  schema
}
