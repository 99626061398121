// @flow
import React from 'react'
import { Slide, Typography } from '@material-ui/core'

function SlideMessage({
  type = 'success',
  message,
  ...props
}: {
  type: 'success' | 'error',
  message: string,
  props?: Object
}) {
  return (
    <Slide direction='left' mountOnEnter unmountOnExit {...props}>
      <Typography
        color={type === 'success' ? 'primary' : 'secondary'}
        variant='body2'
      >
        {message}
      </Typography>
    </Slide>
  )
}

export default SlideMessage
