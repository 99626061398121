// @flow
import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { TextField } from 'components'

function LabelValue({
  editable = false,
  label,
  value,
  ...props
}: {
  editable?: boolean,
  label: string,
  value: string,
  props?: Object
}) {
  return (
    <Grid item sm={6} xs={12}>
      {editable ? (
        <TextField label={label} value={value} {...props} />
      ) : (
        <Typography variant='h6'>
          {label}
          <Typography variant='body2'>{value || 'N/A'}</Typography>
        </Typography>
      )}
    </Grid>
  )
}

export default LabelValue
