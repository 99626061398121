import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'

import { Button, FilePreview } from 'components'

function FileUpload({
  onChange,
  value,
  label = 'Tomar Foto',
  name = 'FileUpload',
  loading = false
}: {
  onChange: Function,
  value: string,
  label: string,
  name: string,
  loading: Boolean
}) {
  const classes = useStyles()

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        className={classes.container}
        my={1}
      >
        <>
          <input
            className={classes.input}
            id={name}
            type='file'
            accept='image/*'
            onChange={onChange}
          />
          <label className={classes.label} htmlFor={name}>
            <Button component='span'>{label}</Button>
          </label>
        </>
      </Box>

      {value && !loading && <FilePreview value={value} />}

      {loading && <Skeleton className={classes.skeleton} />}
    </>
  )
}

const useStyles = makeStyles((theme: Object) => ({
  container: { width: '100%' },
  input: { display: 'none' },
  label: { flex: 1 },
  skeleton: {
    height: 350
  },
  icon: {
    color: theme.palette.common.black
  }
}))

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool
}

export default FileUpload
