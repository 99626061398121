// @flow
import * as yup from 'yup'

import { regex } from 'utils'

const initialValues = {
  idNumber: '',
  name: '',
  phoneNumber: '',
  address: '',
  email: '',
  description: '',
  workplace: '',
  idFrontPhoto: null,
  idBackPhoto: null
}

const schema = yup.object().shape({
  idNumber: yup
    .string()
    .required('La cedúla es obligatoria.')
    .matches(regex.onlyNumbers, 'La cédula solo debe contener numeros.')
    .min(9, 'El mínimo de numeros para la cedúla son 9.'),
  name: yup
    .string()
    .required('El nombre es obligatorio.')
    .matches(regex.onlyLetters, 'El nombre solo puede llevar letras.'),
  phoneNumber: yup
    .string()
    .required('El teléfono es obligatorio.')
    .matches(regex.onlyNumbers, 'El teléfono solo debe contener numeros.')
    .min(8, 'El mínimo de numeros para el teléfono son 8.'),
  address: yup.string().required('La dirección es obligatoria.'),
  email: yup.string().email('Debe ser un email valido'),
  description: yup.string(),
  workplace: yup.string(),
  idFrontPhoto: yup.string().nullable(),
  idBackPhoto: yup.string().nullable()
})

export default {
  initialValues,
  schema
}
