// @flow
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link as MuiLink } from '@material-ui/core'

const LinkRef = React.forwardRef(function Link(props: Object, ref: Object) {
  return (
    <MuiLink component={RouterLink} ref={ref} underline='none' {...props} />
  )
})

export default LinkRef
