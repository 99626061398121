// @flow
import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid
} from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { Formik, Form, Field } from 'formik'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { TextField, Button, Snackbar, FileUpload, Map } from 'components'
import { clientScheme } from 'schemas'
import { usePost, useFileUpload } from 'hooks'
import { mapConfig } from 'config'

const { initialValues, schema } = clientScheme

function AddClient() {
  const classes = useStyles()
  const history = useHistory()
  const { post, isError, loading, error } = usePost()
  const {
    upload: uploadFront,
    loading: uploadFrontLoading,
    error: uploadFrontError
  } = useFileUpload()
  const {
    upload: uploadBack,
    loading: uploadBackLoading,
    error: uploadBackError
  } = useFileUpload()
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false)
  const [geolocation, setGeolocation] = useState(null)
  const geolocationClient = navigator?.geolocation
  const geography = geolocation
    ? {
        type: 'Point',
        coordinates: [geolocation.lng, geolocation.lat]
      }
    : null

  const onMapClick = (value: Object) => {
    setGeolocation({
      lat: value.lat,
      lng: value.lng
    })
  }

  const handleShowCurrentLocation = () => {
    if (geolocationClient) {
      setLoadingCurrentLocation(true)
      geolocationClient.getCurrentPosition((position: Object) => {
        const { latitude: lat, longitude: lng } = position.coords
        setGeolocation({
          lat,
          lng
        })
        setLoadingCurrentLocation(false)
      })
    }
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(isError && error)}
        type='error'
        message={typeof error === 'string' ? error : error && error.message}
      />

      <Snackbar
        isVisible={Boolean(uploadFrontError)}
        type='error'
        message={
          typeof uploadFrontError === 'string'
            ? uploadFrontError
            : uploadFrontError && uploadFrontError.message
        }
      />

      <Snackbar
        isVisible={Boolean(uploadBackError)}
        type='error'
        message={
          typeof uploadBackError === 'string'
            ? uploadBackError
            : uploadBackError && uploadBackError.message
        }
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values: Object) =>
          post(
            'client/create',
            {
              ...values,
              geolocation: geography
            },
            (client: Object) => {
              history.push(`/inicio/clientes/perfil/${client.id}`, client)
            }
          )
        }
      >
        {({ errors, touched }: { errors: Object, touched: Object }) => (
          <Form>
            <Card>
              <CardHeader title='Cliente' />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='idNumber'
                      as={TextField}
                      label='Número de cédula *'
                      error={Boolean(touched.idNumber && errors.idNumber)}
                      helperText={touched.idNumber && errors.idNumber}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='name'
                      as={TextField}
                      label='Nombre *'
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='phoneNumber'
                      as={TextField}
                      label='Número de teléfono *'
                      type='tel'
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='address'
                      as={TextField}
                      label='Dirección *'
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='email'
                      as={TextField}
                      type='email'
                      label='Correo'
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='description'
                      as={TextField}
                      label='Descripción'
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='workplace'
                      as={TextField}
                      label='Lugar de trabajo'
                      error={Boolean(touched.workplace && errors.workplace)}
                      helperText={touched.workplace && errors.workplace}
                    />
                  </Grid>

                  <Grid item sm={8} xs={12} />

                  <Grid item sm={6} xs={12}>
                    <Field name='idFrontPhoto'>
                      {({
                        field: { name, value },
                        form: { setFieldValue }
                      }: {
                        field: Object,
                        form: Object
                      }) => {
                        return (
                          <FileUpload
                            name={name}
                            label='Foto del frente de la cédula'
                            loading={uploadFrontLoading}
                            value={value}
                            onChange={async ({
                              target: {
                                validity,
                                files: [file]
                              }
                            }: {
                              target: Object
                            }) => {
                              try {
                                if (validity.valid && file) {
                                  setFieldValue('idFrontPhoto', '')

                                  const { name, type } = file
                                  const newName = `${Date.now().toString()}${name}`

                                  const fileCopy = new File([file], newName, {
                                    type
                                  })

                                  await uploadFront({
                                    name: fileCopy.name,
                                    type: fileCopy.type,
                                    file: fileCopy,
                                    throwError: true
                                  })

                                  setFieldValue('idFrontPhoto', newName)
                                }
                              } catch (error) {
                                setFieldValue('idFrontPhoto', '')
                              }
                            }}
                          />
                        )
                      }}
                    </Field>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Field name='idBackPhoto'>
                      {({
                        field: { name, value },
                        form: { setFieldValue }
                      }: {
                        field: Object,
                        form: Object
                      }) => {
                        return (
                          <FileUpload
                            name={name}
                            label='Foto de atrás de la cédula'
                            loading={uploadBackLoading}
                            value={value}
                            onChange={async ({
                              target: {
                                validity,
                                files: [file]
                              }
                            }: {
                              target: Object
                            }) => {
                              try {
                                if (validity.valid && file) {
                                  setFieldValue('idBackPhoto', '')

                                  const { name, type } = file
                                  const newName = `${Date.now().toString()}${name}`

                                  const fileCopy = new File([file], newName, {
                                    type
                                  })

                                  await uploadBack({
                                    name: fileCopy.name,
                                    type: fileCopy.type,
                                    file: fileCopy,
                                    throwError: true
                                  })

                                  setFieldValue('idBackPhoto', newName)
                                }
                              } catch (error) {
                                setFieldValue('idBackPhoto', '')
                              }
                            }}
                          />
                        )
                      }}
                    </Field>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Map
                      loadingCurrentLocation={loadingCurrentLocation}
                      defaultCenter={mapConfig.center}
                      onClick={onMapClick}
                      center={geolocation}
                      showCurrentLocation={handleShowCurrentLocation}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button disabled={loading} fullWidth={false}>
                  <SaveIcon className={classes.icon} />
                  Agregar
                </Button>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  )
}

const useStyles = makeStyles((theme: Object) => ({
  icon: {
    marginRight: theme.spacing(1)
  }
}))

export default AddClient
