// @flow
import React, { useState } from 'react'
import {
  Avatar,
  Typography,
  Container,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, Field } from 'formik'
import { useHistory } from 'react-router-dom'

import { TextField, Button, LoadingButton, Snackbar } from 'components'
import { loginScheme } from 'schemas'
import { auth } from 'utils'
import { fetch } from 'api'

const { initialValues, schema } = loginScheme

function Login() {
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const history = useHistory()
  const classes = useStyles()

  return (
    <Container maxWidth='xs' className={classes.container}>
      <Avatar className={classes.avatar}>
        <LockOutlined />
      </Avatar>
      <Typography className={classes.mb} component='h1' variant='h5'>
        Inicia Sesión
      </Typography>

      <Snackbar isVisible={error !== ''} type='error' message={error} />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values: Object) => {
          try {
            setLoading(true)
            setError('')
            const response = await fetch.post('login', values)
            setLoading(false)
            auth.setToken(response.token)
            history.push('/inicio')
          } catch (err) {
            setError(err)
            setLoading(false)
          }
        }}
      >
        {({ errors, touched }: { errors: Object, touched: Object }) => (
          <Form>
            <Field
              name='userName'
              as={TextField}
              label='Nombre de usuario'
              error={Boolean(touched.userName && errors.userName)}
              helperText={touched.userName && errors.userName}
            />

            <Field
              name='password'
              as={TextField}
              label='Contraseña'
              type={showPassword ? 'text' : 'password'}
              autoComplete='current-password'
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <LoadingButton loading={loading}>
              <Button disabled={loading}>Iniciar Sesión</Button>
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

const useStyles = makeStyles((theme: Object) => ({
  container: {
    paddingTop: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  mb: {
    marginBottom: 30
  },
  avatar: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}))

export default Login
