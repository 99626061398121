// @flow
import { useCallback, useReducer } from 'react'

import { fetch } from 'api'

const useFileUpload = () => {
  const [state, setState] = useReducer(
    (state: Object, newState: Object) => ({ ...state, ...newState }),
    {
      loading: false,
      error: null,
      data: null
    }
  )

  const upload = useCallback(
    async ({
      name,
      type,
      file,
      throwError = false
    }: {
      name: string,
      type: Object,
      file: Object,
      throwError: Boolean
    }) => {
      try {
        setState({
          loading: true,
          error: null
        })

        const { url } = await fetch.get(
          `s3/getSignedUrl?name=${name}&type=${type}`
        )

        const response = await fetch.fileUpload(url, file)

        setState({
          data: response,
          loading: false
        })

        return response
      } catch (error) {
        setState({
          error,
          loading: false
        })

        if (throwError) {
          throw new Error(error)
        }
      }
    },
    []
  )

  return {
    ...state,
    upload
  }
}

export default useFileUpload
