import create from './create'
import update from './update'
import pay from './pay'

const validators = {
  create,
  pay,
  update
}

export default validators
