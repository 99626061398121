const getTableInfo = (tableName: string) => {
  switch (tableName) {
    case 'payments':
      return {
        name: 'Pagos',
        path: 'pagos'
      }

    case 'loans':
      return {
        name: 'Prestamos',
        path: 'prestamos'
      }

    case 'clients':
      return {
        name: 'Clientes',
        path: 'clientes'
      }

    default:
      return {
        name: tableName,
        path: tableName
      }
  }
}

export default {
  getTableInfo
}
