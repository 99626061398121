// @flow
import React from 'react'
import NumberFormat from 'react-number-format'

import TextField from '../TextField'

function AmountField(props?: Object) {
  return (
    <NumberFormat
      isNumericString={true}
      thousandSeparator
      customInput={TextField}
      prefix='₡'
      allowNegative={false}
      {...props}
    />
  )
}

export default AmountField
