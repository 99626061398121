// @flow
import React, { useState, useEffect } from 'react'
import {
  Typography,
  Box,
  CircularProgress,
  MenuItem,
  InputAdornment,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, Field } from 'formik'
import { useLocation, useParams, useHistory } from 'react-router-dom'

import {
  Button,
  Snackbar,
  TextField,
  FilePreview,
  FileUpload
} from 'components'
import { userScheme } from 'schemas'
import { useGet, usePost, useFileUpload } from 'hooks'
import { auth, formatDate } from 'utils'

const { schema } = userScheme.update

function Profile() {
  const classes = useStyles()
  const [data, setData] = useState(null)
  const [isEditable, setIsEditable] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { state, pathname } = useLocation()
  const { id } = useParams()
  const history = useHistory()
  const { id: userId } = auth.decodedToken()
  const isAdmin = auth.isAdmin()
  const { loading: userLoading, error: userError, data: userData } = useGet(
    state ? null : `user/${id}`
  )
  const {
    upload: uploadFront,
    loading: uploadFrontLoading,
    error: uploadFrontError
  } = useFileUpload()
  const {
    upload: uploadBack,
    loading: uploadBackLoading,
    error: uploadBackError
  } = useFileUpload()

  const {
    data: userPropsData,
    loading: userPropsLoading,
    error: userPropsError
  } = useGet('user/props')

  const {
    post,
    isError: postIsError,
    loading: postLoading,
    error: postError
  } = usePost()

  useEffect(() => {
    if (!state) {
      return
    }

    const stateCopy = {
      ...state
    }

    if (stateCopy && stateCopy.tableData) {
      delete stateCopy.tableData
    }

    setData(stateCopy)
  }, [state])

  useEffect(() => {
    if (!userData) {
      return
    }

    history.replace({
      pathname,
      state: userData
    })
  }, [userData, history, pathname])

  if (
    (userLoading || (!userData && !data) || (userData && !data)) &&
    !userError
  ) {
    return (
      <Card>
        <CardHeader title={<Skeleton height={50} width='100%' />} />
        <CardContent>
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
        </CardContent>
      </Card>
    )
  }

  if (userPropsError || userError) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {userPropsError || userError}
        </Typography>
      </Box>
    )
  }

  if (!data) {
    return (
      <Card>
        <CardHeader title={`No existe un usuario con el id ${id}`} />
      </Card>
    )
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(postIsError && postError)}
        type='error'
        message={
          typeof postError === 'string'
            ? postError
            : postError && postError.message
        }
      />

      <Snackbar
        isVisible={Boolean(uploadBackError)}
        type='error'
        message={
          typeof uploadBackError === 'string'
            ? uploadBackError
            : uploadBackError && uploadBackError.message
        }
      />

      <Snackbar
        isVisible={Boolean(uploadFrontError)}
        type='error'
        message={
          typeof uploadFrontError === 'string'
            ? uploadFrontError
            : uploadFrontError && uploadFrontError.message
        }
      />

      <Formik
        initialValues={data}
        validationSchema={schema}
        onSubmit={async (values: Object) => {
          post('user/update', values, (user: Object) => {
            history.replace({ pathname, state: user })
            setIsEditable(false)
          })
        }}
      >
        {({
          errors,
          touched,
          resetForm,
          values
        }: {
          errors: Object,
          touched: Object,
          resetForm: Function,
          values: Object
        }) => (
          <Form className={classes.form}>
            <Card>
              <CardHeader
                title='Usuario'
                action={
                  !isAdmin ? null : isEditable ? (
                    <Button
                      type='button'
                      fullWidth={false}
                      disabled={postLoading}
                      onClick={() => {
                        resetForm(data)
                        setIsEditable(false)
                      }}
                    >
                      <CloseIcon className={classes.icon} />
                      Cerrar
                    </Button>
                  ) : (
                    <Button
                      type='button'
                      fullWidth={false}
                      onClick={() => setIsEditable(true)}
                    >
                      <EditIcon className={classes.icon} />
                      Editar
                    </Button>
                  )
                }
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='idNumber'
                      as={TextField}
                      label='Número de cédula'
                      readOnly={!isEditable}
                      error={Boolean(touched.idNumber && errors.idNumber)}
                      helperText={touched.idNumber && errors.idNumber}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='name'
                      as={TextField}
                      label='Nombre'
                      readOnly={!isEditable}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='phoneNumber'
                      as={TextField}
                      label='Número de teléfono'
                      readOnly={!isEditable}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name='email'
                      as={TextField}
                      label='Correo'
                      type='email'
                      readOnly={!isEditable}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field name='userTypeId'>
                      {({ field }: { field: Object }) => {
                        if (userPropsLoading) {
                          return <CircularProgress />
                        }

                        return (
                          <TextField
                            select
                            {...field}
                            label='Tipo*'
                            readOnly={!isEditable}
                            error={Boolean(
                              touched.userTypeId && errors.userTypeId
                            )}
                            helperText={touched.userTypeId && errors.userTypeId}
                          >
                            {userPropsData.userTypes.map(
                              ({ id, name }: { id: number, name: string }) => (
                                <MenuItem key={id} value={id}>
                                  {name}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        )
                      }}
                    </Field>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field name='userStatusId'>
                      {({ field }: { field: Object }) => {
                        if (userPropsLoading) {
                          return <CircularProgress />
                        }

                        return (
                          <TextField
                            select
                            {...field}
                            label='Estato*'
                            readOnly={!isEditable}
                            error={Boolean(
                              touched.userStatusId && errors.userStatusId
                            )}
                            helperText={
                              touched.userStatusId && errors.userStatusId
                            }
                          >
                            {userPropsData.userStatuses.map(
                              ({ id, name }: { id: number, name: string }) => (
                                <MenuItem key={id} value={id}>
                                  {name}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        )
                      }}
                    </Field>
                  </Grid>

                  {isEditable && userId === state.id && (
                    <>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name='userName'
                          as={TextField}
                          label='Nombre de usuario'
                          error={Boolean(touched.userName && errors.userName)}
                          helperText={touched.userName && errors.userName}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name='password'
                          as={TextField}
                          label='Contraseña'
                          type={showPassword ? 'text' : 'password'}
                          autoComplete='current-password'
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='Toggle password visibility'
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item sm={4} xs={12}>
                    <Field
                      name='createdAt'
                      as={TextField}
                      label='Fecha de creación'
                      value={formatDate(values.createdAt)}
                      readOnly
                      disabled={isEditable}
                    />
                  </Grid>

                  {userId !== state.id && <Grid item sm={4} xs={12} />}

                  <Grid item sm={6} xs={12}>
                    <Field name='idFrontPhoto'>
                      {({
                        field: { name, value },
                        form: { setFieldValue }
                      }: {
                        field: Object,
                        form: Object
                      }) => {
                        if (!value && !isEditable) {
                          return null
                        }

                        if (!isEditable) {
                          return <FilePreview value={value} />
                        }

                        return (
                          <FileUpload
                            name={name}
                            label='Foto del frente de la cédula'
                            loading={uploadFrontLoading}
                            value={value}
                            onChange={async ({
                              target: {
                                validity,
                                files: [file]
                              }
                            }: {
                              target: Object
                            }) => {
                              try {
                                if (validity.valid && file) {
                                  setFieldValue('idFrontPhoto', '')

                                  const { name, type } = file
                                  const newName = `${Date.now().toString()}${name}`

                                  const fileCopy = new File([file], newName, {
                                    type
                                  })

                                  await uploadFront({
                                    name: fileCopy.name,
                                    type: fileCopy.type,
                                    file: fileCopy,
                                    throwError: true
                                  })

                                  setFieldValue('idFrontPhoto', newName)
                                }
                              } catch (error) {
                                setFieldValue('idFrontPhoto', '')
                              }
                            }}
                          />
                        )
                      }}
                    </Field>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Field name='idBackPhoto'>
                      {({
                        field: { name, value },
                        form: { setFieldValue }
                      }: {
                        field: Object,
                        form: Object
                      }) => {
                        if (!value && !isEditable) {
                          return null
                        }

                        if (!isEditable) {
                          return <FilePreview value={value} />
                        }

                        return (
                          <FileUpload
                            name={name}
                            label='Foto del atrás de la cédula'
                            loading={uploadBackLoading}
                            value={value}
                            onChange={async ({
                              target: {
                                validity,
                                files: [file]
                              }
                            }: {
                              target: Object
                            }) => {
                              try {
                                if (validity.valid && file) {
                                  setFieldValue('idBackPhoto', '')

                                  const { name, type } = file
                                  const newName = `${Date.now().toString()}${name}`

                                  const fileCopy = new File([file], newName, {
                                    type
                                  })

                                  await uploadBack({
                                    name: fileCopy.name,
                                    type: fileCopy.type,
                                    file: fileCopy,
                                    throwError: true
                                  })

                                  setFieldValue('idBackPhoto', newName)
                                }
                              } catch (error) {
                                setFieldValue('idBackPhoto', '')
                              }
                            }}
                          />
                        )
                      }}
                    </Field>
                  </Grid>
                </Grid>
              </CardContent>
              {isAdmin && isEditable && (
                <CardActions>
                  <Button disabled={postLoading} fullWidth={false}>
                    <SaveIcon className={classes.icon} />
                    Guardar
                  </Button>
                </CardActions>
              )}
            </Card>
          </Form>
        )}
      </Formik>
    </>
  )
}

const useStyles = makeStyles({
  form: {
    marginTop: 20,
    width: '100%'
  }
})

export default Profile
