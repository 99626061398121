// @flow
import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import Login from 'screens/Login'
import Home from 'screens/Home'
import NoMatch from 'screens/NoMatch'
import { CustomRoute } from 'components'

function App() {
  return (
    <Router>
      <Switch>
        <CustomRoute path='/inicio' component={Home} isPrivate />
        <CustomRoute exact path='/' component={Login} isLogin />
        <CustomRoute component={NoMatch} />
      </Switch>
    </Router>
  )
}

export default App
